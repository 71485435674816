import {URLS} from "../../../configs/routes_url";
import {lazy} from "react";

const delayLazy = 0;

const BankTransferLazy = lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, delayLazy));
    return import('../../../components/pages/BankTransfer/BankTransfer.container');
});

export const PublicRoutes = [
    {
        href: URLS.BANK_TRANSFER,
        component: BankTransferLazy
    }
]
export const SecureRoutes = [
]