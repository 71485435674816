export const URLS = {
    DASHBOARD: '/dashboard',
    SIGN_IN: '/sign-in',
    PROFILE: '/profile',
    WITHDRAWAL: '/withdrawal',
    CHANGE_PASSWORD: '/change-password',
    EDIT_PROFILE: '/edit-profile',
    INBOX: '/inbox',
    NOTIFICATION: '/notification',
    MESSAGE: '/message',
    DEPOSIT: '/deposit',
    FUND_OUT: '/fund-out/create',
    SETTLEMENT: '/settlement/create',
    FUND_OUT_HISTORY: '/fund-out/history',
    SETTLEMENT_HISTORY: '/settlement/history',
    CREATE_BANK_ACCOUNT: '/bank-account/create',
    LIST_BANK_ACCOUNT: '/bank-account/list',
    REPORT_FUND_SETTLEMENT: '/report/fund-settlement',
    REPORT_FUND_OUT: '/report/fund-out',
    REPORT_TOTAL: '/report/total',
    MEMBER: '/member',
    USERS: '/users',
    BANK_TRANSFER: '/bank-transfer',
    BALANCE_LOG: '/balance-log'
}