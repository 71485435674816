import {fromJS} from 'immutable';
import {ReduxActionTypes} from './action-type';

const initialState: any = fromJS({
    memberList: [],
    userList: [],
    isOpenPopUpCreateMember: false,
    isOpenPopUpCreateBankMember: false
})

export default function (state = initialState, action: any) {
    switch (action.type) {
        case ReduxActionTypes.SET_USER_LIST:
            return state.set('userList', action.userList)

        case ReduxActionTypes.SET_MEMBER_LIST:
            return state.set('memberList', action.memberList)

        case ReduxActionTypes.TOGGLE_OPEN_POPUP_CREATE_MEMBER:
             return state.set('isOpenPopUpCreateMember', action.isOpenPopUpCreateMember)

        case ReduxActionTypes.TOGGLE_OPEN_POPUP_CREATE_BANK_MEMBER:
             return state.set('isOpenPopUpCreateBankMember', action.isOpenPopUpCreateBankMember)
        default:
            return state;
    }
}
