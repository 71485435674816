import React from "react";
import {Route} from "react-router-dom";
import {connect} from "react-redux";
import {stateAccountSelectors} from "../../../modules/accounts";
import RootState from "../../../modules/rootState";
import AccessControl from "../../access-control";
import AccessDenied from "../../pages/Error/AccessDenied";

export interface StateFromProps {
    isAuthenticated: boolean
    userInfo: any
}

export interface DispatchFromProps {
}

type Props = any | StateFromProps & DispatchFromProps;

type State = {};

class SecureRoute extends React.Component<Props, State> {

    render() {
        const {component: Component, ...rest} = this.props
        return (
            <Route
                {...rest}
                render={props => (rest.isAuthenticated
                        ? <AccessControl {...props} Component={Component} Data={this.props.userInfo}/>
                        : <AccessDenied {...props}/>
                )}
            />
        )
    }
}

const mapStateToProps = (rootState: RootState) => ({
    isAuthenticated: stateAccountSelectors.getAuthenticationStatus(rootState),
    userInfo: stateAccountSelectors.getUserInfo(rootState)
});

const mapDispatchToProps = (dispatch: any) => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SecureRoute) as any
