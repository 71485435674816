import {initializeApp} from "firebase/app";
import {getDatabase} from "firebase/database"

function StartFireBase() {
    const firebaseConfig = {
        apiKey: "AIzaSyBVUs2BRP9jf1vkqmQfbI4qvY4Oyk-ieEs",
        authDomain: "nonopay-43203.firebaseapp.com",
        projectId: "nonopay-43203",
        storageBucket: "nonopay-43203.appspot.com",
        messagingSenderId: "424039458243",
        appId: "1:424039458243:web:f2a824369f746d72d30976",
        measurementId: "G-ZVWNE8BZSN"
    };
    const app = initializeApp(firebaseConfig);
    return getDatabase(app);
}

export default StartFireBase