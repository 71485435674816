import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaActionTypes} from './action-type';
import {API_URLS} from "../../configs/api_url";
import {reduxActions, sagaActions} from "./action";
import API from '../../api/index';
import {reduxActions as reduxCommonAction} from '../common/action'
import {toast} from "react-toastify";

function* fetchUserList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        const {data} = yield call(API.get, API_URLS.GET_USER_LIST)

        yield put(reduxActions.setUserList(data.result))

    } catch (e) {
        console.log(e);
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* fetchMemberList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        const {data} = yield call(API.get, API_URLS.GET_MEMBER_LIST)

        yield put(reduxActions.setMemberList(data.result))

    } catch (e) {
        console.log(e);
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* createMember(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData()

        formData.append('name', action.memberObject.name)
        formData.append('phone', action.memberObject.phone)
        formData.append('email', action.memberObject.email)

        const {data} = yield call(API.post, API_URLS.CREATE_MEMBER, formData, {headers: headers})

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })

        yield put(sagaActions.fetchMemberList())
        yield put(reduxActions.toggleOpenPopupCreateMember(false))
    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* createBankMember(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData()
        formData.append('member_id', action.bankObject.member_id)
        formData.append('acc_number', action.bankObject.acc_number)
        formData.append('acc_holder_name', action.bankObject.acc_holder_name)
        formData.append('bank_id', action.bankObject.bank_id)

        const {data} = yield call(API.post, API_URLS.CREATE_BANK_MEMBER, formData, {headers: headers})

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
        yield put(sagaActions.fetchMemberList())
        yield put(reduxActions.toggleOpenPopupCreateBankMember(false))
    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

const memberSaga = [
    takeLatest(SagaActionTypes.FETCH_USER_LIST, fetchUserList),
    takeLatest(SagaActionTypes.FETCH_MEMBER_LIST, fetchMemberList),
    takeLatest(SagaActionTypes.CREATE_MEMBER, createMember),
    takeLatest(SagaActionTypes.CREATE_BANK_MEMBER, createBankMember),
]

export default memberSaga
