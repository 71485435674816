import React from "react";
import LoaderImage from '../../../assets/img/common/loader.svg';

export const Fallback = () => {
    return (
        <div>
            <div id="global-loader" className='text-center'>
                <img src={LoaderImage} className="loader-img" alt="Loader"/>
            </div>
        </div>
    )
}
