export const SagaActionTypes = {
    FETCH_MEMBER_LIST: 'FETCH_MEMBER_LIST',
    FETCH_USER_LIST: 'FETCH_USER_LIST',
    CREATE_MEMBER: 'CREATE_MEMBER',
    CREATE_BANK_MEMBER: 'CREATE_BANK_MEMBER'
};

export const ReduxActionTypes = {
    SET_MEMBER_LIST: 'SET_MEMBER_LIST',
    SET_USER_LIST: 'SET_USER_LIST',
    TOGGLE_OPEN_POPUP_CREATE_MEMBER: 'TOGGLE_OPEN_POPUP_CREATE_MEMBER',
    TOGGLE_OPEN_POPUP_CREATE_BANK_MEMBER: 'TOGGLE_OPEN_POPUP_CREATE_BANK_MEMBER'
};
