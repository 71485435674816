import {ReduxActionTypes, SagaActionTypes} from './action-type'

//==============MIDDLE WARE======================
//==============SAGA======================

export const sagaActions = {
    fetchUserList: (): any => ({
        type: SagaActionTypes.FETCH_USER_LIST,
    }),

    fetchMemberList: (): any => ({
        type: SagaActionTypes.FETCH_MEMBER_LIST,
    }),

    createMember: (memberObject: any) => ({
        type: SagaActionTypes.CREATE_MEMBER,
        memberObject
    }),

    createBankMember: (bankObject: any) => ({
        type: SagaActionTypes.CREATE_BANK_MEMBER,
        bankObject
    })

}


//===============REDUX===================

export const reduxActions = {
    setUserList: (userList): any => ({
        type: ReduxActionTypes.SET_USER_LIST,
        userList
    }),

    setMemberList: (memberList): any => ({
        type: ReduxActionTypes.SET_MEMBER_LIST,
        memberList
    }),

    toggleOpenPopupCreateMember: (isOpenPopUpCreateMember: boolean): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_POPUP_CREATE_MEMBER,
        isOpenPopUpCreateMember
    }),

    toggleOpenPopupCreateBankMember: (isOpenPopUpCreateBankMember: boolean): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_POPUP_CREATE_BANK_MEMBER,
        isOpenPopUpCreateBankMember
    })
}

