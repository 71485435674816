import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaActionTypes} from './action-type';
import {API_URLS} from "../../configs/api_url";
import {toast} from "react-toastify";
import {reduxActions, sagaActions} from "./action";
import {reduxActions as transactionReduxAction} from "../transactions/action";
import API from '../../api/index';
import {URLS} from "../../configs/routes_url";
import {reduxActions as reduxCommonAction, sagaActions as commonSagaActions} from "../common/action";
import moment from "moment";


function* login(action: any) {
    yield put(reduxActions.loginRequest());
    try {
        let headers = {"Content-Type": "multipart/form-data"}

        let formData = new FormData()
        formData.append('username', action.loginObject.username)
        formData.append('password', action.loginObject.password)

        const {data} = yield call(API.post, API_URLS.LOGIN, formData, {headers: headers})

        if (data.result.is_enable_2fa) {
            yield put(reduxActions.setToggleVerification2FALogin(true))
        } else {
            let authHeader = {"Authorization": `Bearer ${data.result.token}`}
            const {data: userInfo} = yield call(API.get, API_URLS.GET_PROFILE, {headers: authHeader})

            yield put(reduxActions.setUserInfo(userInfo.result))
            yield put(reduxActions.loginSuccess(data.result.token))

            if (!userInfo.result.is_agent_access_to_report) {
                yield put(commonSagaActions.redirect(URLS.DASHBOARD))
            } else {
                yield put(commonSagaActions.redirect(URLS.REPORT_FUND_SETTLEMENT))
            }

        }
    } catch (e) {
        yield put(reduxActions.loginFail(e.response.data.message))

        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    }
}

function* verification_login(action: any) {

    yield put(reduxActions.loginRequest());
    try {
        let headers = {"Content-Type": "multipart/form-data"}

        let formData = new FormData()
        formData.append('username', action.loginObject.username)
        formData.append('password', action.loginObject.password)
        formData.append('code', action.loginObject.code)

        const {data} = yield call(API.post, API_URLS.VERIFICATION_LOGIN, formData, {headers: headers})
        let authHeader = {"Authorization": `Bearer ${data.result.token}`}
        const {data: userInfo} = yield call(API.get, API_URLS.GET_PROFILE, {headers: authHeader})

        yield put(reduxActions.setUserInfo(userInfo.result))
        yield put(reduxActions.loginSuccess(data.result.token))

        if (!userInfo.result.is_agent_access_to_report) {
            yield put(commonSagaActions.redirect(URLS.DASHBOARD))
        } else {
            yield put(commonSagaActions.redirect(URLS.REPORT_FUND_SETTLEMENT))
        }

    } catch (e) {
        yield put(reduxActions.loginFail(e.response.data.message))
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    }
}

function* fetchUserProfile(action: any) {
    try {
        const {data} = yield call(API.get, API_URLS.GET_PROFILE)
        yield put(reduxActions.setUserInfo(data.result))
    } catch (e) {
        console.log(e);
    }
}

function* changePassword(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('old_password', action.changePasswordObject.old_password);
        formData.append('new_password', action.changePasswordObject.new_password);
        formData.append('confirm_password', action.changePasswordObject.confirm_password);

        const {data} = yield call(API.post, API_URLS.CHANGE_PASSWORD, formData, {headers: headers})

        yield put(reduxCommonAction.makePageLoading(false))

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
        setTimeout(() => window.location.href = "/", 1000)
    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* updateUserProfile(action: any) {
    try {

        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}

        let formData = new FormData();
        formData.append('phone', action.profileObject.phone)
        formData.append('email', action.profileObject.email)
        formData.append('street', action.profileObject.street)
        formData.append('country_id', action.profileObject.country_id)
        formData.append('state_id', action.profileObject.state_id)

        const {data} = yield call(API.post, API_URLS.UPDATE_PROFILE, formData, {headers: headers})
        yield put(sagaActions.fetchUserProfile())

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* fetchUserNotification(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        const {data} = yield call(API.get, API_URLS.SEARCH_NOTIFICATION)

        let notificationList = [...data.result]
        for (let i = 0; i < notificationList.length; i++) {
            let localTime = moment.utc(notificationList[i].notification_date)
            notificationList[i].notification_date = localTime.utcOffset("+08:00").format('YYYY-MM-DD HH:mm:ss')
        }
        yield put(reduxActions.setUserNotification(notificationList))

    } catch (e) {
        console.log(e.response)
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* updateUserNotification(action: any) {
    try {
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData()
        formData.append('notification_id', action.notificationObject.notification_id)

        yield call(API.post, API_URLS.UPDATE_NOTIFICATION, formData, {headers: headers})

    } catch (e) {
        // Swal.fire({icon: 'error', text: e.response.data.message})
        console.log(e.response.data.message)
    }
}

function* transferWallet(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData()
        formData.append('amount', action.amount)
        formData.append('wallet_type', action.walletType)

        const {data} = yield call(API.post, API_URLS.TRANSFER_WALLET, formData, {headers: headers})
        if (data.success) {
            if (data.result) {
                yield put(transactionReduxAction.setConfirmUSDTData(data.result))
            } else {
                toast.success(data.message, {
                    position: "bottom-right",
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                })
                yield put(sagaActions.fetchUserProfile())
                yield put(transactionReduxAction.toggleOpenPopupTransferWallet('', false))
            }
        }
    } catch (e) {
        if (e.response) {
            toast.error(e.response.data.message, {
                position: "bottom-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* changePasswordSettlement(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();

        formData.append('old_password', action.object.old_password);
        formData.append('new_password', action.object.new_password);
        formData.append('confirm_password', action.object.confirm_password);

        const {data} = yield call(API.post, API_URLS.CHANGE_PASSWORD_SETTLEMENT, formData, {headers: headers})

        yield put(reduxCommonAction.makePageLoading(false))

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })

        yield put(reduxActions.setToggleOpenPopupChangePasswordSettlement(false))

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* checkStatusSettlementPassword(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();

        formData.append('is_enable', action.object.isEnable);
        formData.append('password', action.object.password);

        const {data} = yield call(API.post, API_URLS.CHECK_STATUS_SETTLEMENT_PASSWORD, formData, {headers: headers})

        yield put(reduxCommonAction.makePageLoading(false))

        yield put(reduxActions.setToggleOpenPopupDisableSettlementPassword(false))
        yield put(reduxActions.setToggleOpenPopupEnableSettlementPassword(false))

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
        yield put(sagaActions.fetchUserProfile())

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* getQrCode2FA(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        const {data} = yield call(API.get, API_URLS.GET_QRCODE_2FA)

        yield put(reduxActions.setQrCode2FA(data.result))

    } catch (e) {
        console.log(e.response)
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* verificationQrCode2FA(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();

        formData.append('is_enable', action.object.isEnable);
        formData.append('code', action.object.code);

        const {data} = yield call(API.post, API_URLS.VERIFICATION_QRCODE_2FA, formData, {headers: headers})

        yield put(reduxCommonAction.makePageLoading(false))

        yield put(reduxActions.setToggleOpenPopupEnable2FA(false))
        yield put(reduxActions.setToggleOpenPopupDisable2FA(false))

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
        yield put(sagaActions.fetchUserProfile())

    } catch (e) {
        yield put(reduxCommonAction.makePageLoading(false))
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    }
}

function* exchangeUSDT(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData()
        formData.append('exchange_id', action.exchangeId)
        const {data} = yield call(API.post, API_URLS.EXCHANGE_USDT, formData, {headers: headers})
        if (data.success) {
            toast.success(data.message, {
                position: "bottom-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
            yield put(sagaActions.fetchUserProfile())
            yield put(transactionReduxAction.toggleOpenPopupTransferWallet('', false))
            yield put(transactionReduxAction.hideIsShowConfirmUSDT())
        }
    } catch (e) {
        if (e.response) {
            toast.error(e.response.data.message, {
                position: "bottom-right",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

const accountSaga = [
    takeLatest(SagaActionTypes.LOGIN, login),
    takeLatest(SagaActionTypes.FETCH_USER_PROFILE, fetchUserProfile),
    takeLatest(SagaActionTypes.CHANGE_PASSWORD, changePassword),
    takeLatest(SagaActionTypes.UPDATE_USER_PROFILE, updateUserProfile),
    takeLatest(SagaActionTypes.FETCH_USER_NOTIFICATION, fetchUserNotification),
    takeLatest(SagaActionTypes.UPDATE_NOTIFICATION, updateUserNotification),
    takeLatest(SagaActionTypes.TRANSFER_WALLET, transferWallet),
    takeLatest(SagaActionTypes.CHANGE_PASSWORD_SETTLEMENT, changePasswordSettlement),
    takeLatest(SagaActionTypes.CHECK_STATUS_SETTLEMENT_PASSWORD, checkStatusSettlementPassword),
    takeLatest(SagaActionTypes.GET_QRCODE_2FA, getQrCode2FA),
    takeLatest(SagaActionTypes.VERIFICATION_QRCODE_2FA, verificationQrCode2FA),
    takeLatest(SagaActionTypes.VERIFICATION_LOGIN, verification_login),
    takeLatest(SagaActionTypes.EXCHANGE_USDT, exchangeUSDT)
]

export default accountSaga
