import {combineReducers} from 'redux';
import account from './accounts/reducer';
import common from './common/reducer';
import transaction from './transactions/reducer';
import bank from './bank/reducer';
import member from './members/reducer';
import dashboard from './dashboard/reducer';
import report from './reports/reducer';
import balancelog from './balancelog/reducer';
import RootState from "./rootState";
import {connectRouter} from 'connected-react-router';


const rootReducer = (history) => {
    const appReducer = combineReducers<RootState>({
        router: connectRouter(history),
        account,
        common,
        transaction,
        bank,
        member,
        dashboard,
        report,
        balancelog,
    });
    return appReducer;
};

export default rootReducer;
