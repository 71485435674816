import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaActionTypes} from './action-type'
import {API_URLS} from "../../configs/api_url";
import API from '../../api/index';
import {reduxActions as reduxCommonAction} from '../common/action'

function* redirectRouteWithLang(action: any) {
    // yield put(push(action.redirectTo))
    window.location.replace(action.redirectTo)
}

function* redirect(action: any) {
    const currentLang = localStorage.getItem('i18nextLng')
    if (action.redirectTo === '/') {
        action.redirectTo = '/dashboard'
    }
    if (action.openTab) {
        window.open(`/${currentLang}${action.redirectTo}`)
    } else {
        window.location.href = (`/${currentLang}${action.redirectTo}`)
    }
}

function* fetchRegionList(action: any) {
    try {
        const {data} = yield call(API.get, API_URLS.GET_REGION_LIST)
        if (data.result.length > 0) {
            yield put(reduxCommonAction.setRegionList(data.result))
        }
    } catch (e) {
        console.log(e.response)
    }
}

function* fetchStateList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        const {data} = yield call(API.get, API_URLS.GET_STATE_LIST + "?country_id=" + action.country)
        if (data.result.length > 0) {
            yield put(reduxCommonAction.setStateList(data.result))
        } else {
            yield put(reduxCommonAction.setStateList([]))
        }
    } catch (e) {
        console.log(e.response)
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}


const commonSaga = [
    takeLatest(SagaActionTypes.REDIRECT, redirect),
    takeLatest(SagaActionTypes.REDIRECT_ROUTE_WITH_LANG, redirectRouteWithLang),
    takeLatest(SagaActionTypes.FETCH_REGION_LIST, fetchRegionList),
    takeLatest(SagaActionTypes.FETCH_STATE_LIST, fetchStateList),
]

export default commonSaga
