import {HttpTransportType, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {MiddleWareActionTypes} from "../accounts/action-type";


const startSignalRConnection = connection => connection.start()
    .then()
    .catch(err => console.error('SignalR connection error: ', err));

const signalRMiddleware = store => next => async action => {
    if (action.type === MiddleWareActionTypes.CONNECT_SIGNALR) {

        const connection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_SIGNALR_URL}/userHub?user=${action.userName}`, {
                logger: LogLevel.Error,
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build()

        connection.on('ReceiveConnection', data => {
            console.info(`Connected ${data}`)
        })

        connection.on('ReceiveMessage', data => {
        })

        connection.onclose(() => {
            console.info(`Lost connect, retry to connect again`)
            startSignalRConnection(connection)
        })

        startSignalRConnection(connection);
    }
    return next(action)
}

export default signalRMiddleware;
