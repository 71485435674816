export const SagaActionTypes = {
    REDIRECT: 'REDIRECT',
    REDIRECT_ROUTE_WITH_LANG: 'REDIRECT_ROUTE_WITH_LANG',
    FETCH_REGION_LIST: 'FETCH_REGION_LIST',
    FETCH_STATE_LIST: 'FETCH_STATE_LIST'
};
export const ReduxActionTypes = {
    SET_PAGE_LOADING: 'SET_PAGE_LOADING',
    SET_STATE: 'SET_STATE',
    SET_REGION: 'SET_REGION'
};
