import {fromJS} from 'immutable';
import {ReduxActionTypes} from './action-type';

const initialState: any = fromJS({
    isAuthenticated: false,

      // Login
    isLoginRequest: false,
    loginFailMessage: '',
    userInfo: null,
    notification: [],
    isOpenPopUpViewNotification: false,
    dataNotification: [],
    isOpenPopUpChangePasswordSettlement: false,
    isOpenPopUpEnableSettlementPassword: false,
    isOpenPopUpDisableSettlementPassword: false,
    qrCode: null,
    isOpenPopUpEnable2FA: false,
    isOpenPopUpDisable2FA: false,
    isVerification2FALogin: false
})

export default function (state = initialState, action: any) {
    switch (action.type) {
        //login
         case ReduxActionTypes.LOGIN_SUCCESS:
            localStorage.setItem('token', action.token)
            return state
                .set('isLoginRequest', false)
                .set('loginFailMessage', '')
        case ReduxActionTypes.LOGIN_REQUEST:
            return state
                .set('isLoginRequest', true)
        case ReduxActionTypes.SET_USER_INFO:
            return state
                .set('userInfo', action.userObject)
        case ReduxActionTypes.LOGIN_FAIL:
            return state
                .set('isLoginRequest', false)
                .set('loginFailMessage', action.message)
        //notification
        case ReduxActionTypes.SET_LOGIN_FAIL_MESSAGE:
            return state.set('loginFailMessage', action.message)
        case ReduxActionTypes.SET_USER_NOTIFICATION:
            return state.set('notification', action.notification)

        case ReduxActionTypes.TOGGLE_OPEN_POPUP_VIEW_NOTIFICATION:
            return state.set('isOpenPopUpViewNotification', action.toggleStatus)

        case ReduxActionTypes.SET_DATA_POPUP_NOTIFICATION:
            return state.set('dataNotification', action.dataNotification)

        case ReduxActionTypes.TOGGLE_OPEN_CHANGE_PASSWORD_SETTLEMENT:
            return state.set('isOpenPopUpChangePasswordSettlement', action.toggleStatus)

        case ReduxActionTypes.TOGGLE_OPEN_POPUP_ENABLE_SETTLEMENT_PASSWORD:
            return state.set('isOpenPopUpEnableSettlementPassword', action.toggleStatus)

        case ReduxActionTypes.TOGGLE_OPEN_POPUP_DISABLE_SETTLEMENT_PASSWORD:
            return state.set('isOpenPopUpDisableSettlementPassword', action.toggleStatus)

        case ReduxActionTypes.SET_QRCODE_2FA:
            return state.set('qrCode', action.qrCode)

        case ReduxActionTypes.TOGGLE_OPEN_POPUP_ENABLE_2FA:
            return state.set('isOpenPopUpEnable2FA', action.toggleStatus)

        case ReduxActionTypes.TOGGLE_OPEN_POPUP_DISABLE_2FA:
            return state.set('isOpenPopUpDisable2FA', action.toggleStatus)

        case ReduxActionTypes.TOGGLE_SET_VERIFICATION_2FA_LOGIN:
            return state
                .set('isVerification2FALogin', action.toggleStatus)
                .set('isLoginRequest', false)

        default:
            return state;
    }
}
