import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import home_en from "./assets/languages/en/home.json";
import agentBankAccount_en from "./assets/languages/en/agentBankAccount.json";
import balances_en from "./assets/languages/en/balances.json";
import changePassword_en from "./assets/languages/en/changePassword.json";
import dashboard_en from "./assets/languages/en/dashboard.json";
import deposit_en from "./assets/languages/en/deposit.json";
import notification_en from "./assets/languages/en/notification.json";
import member_en from "./assets/languages/en/member.json";
import message_en from "./assets/languages/en/message.json";
import profile_en from "./assets/languages/en/profile.json";
import report_en from "./assets/languages/en/report.json";
import settlement_en from "./assets/languages/en/settlement.json";
import fundout_en from "./assets/languages/en/fundout.json";
import signIn_en from "./assets/languages/en/signIn.json";
import users_en from "./assets/languages/en/users.json";
import common_en from "./assets/languages/en/common.json";
import header_en from "./assets/languages/en/header.json";
import accessDenied_en from "./assets/languages/en/accessDenied.json";


import home_vi from "./assets/languages/vi/home.json";
import agentBankAccount_vi from "./assets/languages/vi/agentBankAccount.json";
import balances_vi from "./assets/languages/vi/balances.json";
import changePassword_vi from "./assets/languages/vi/changePassword.json";
import dashboard_vi from "./assets/languages/vi/dashboard.json";
import deposit_vi from "./assets/languages/vi/deposit.json";
import notification_vi from "./assets/languages/vi/notification.json";
import member_vi from "./assets/languages/vi/member.json";
import message_vi from "./assets/languages/vi/message.json";
import profile_vi from "./assets/languages/vi/profile.json";
import report_vi from "./assets/languages/vi/report.json";
import settlement_vi from "./assets/languages/vi/settlement.json";
import fundout_vi from "./assets/languages/vi/fundout.json";
import signIn_vi from "./assets/languages/vi/signIn.json";
import users_vi from "./assets/languages/vi/users.json";
import common_vi from "./assets/languages/vi/common.json";
import header_vi from "./assets/languages/vi/header.json";
import accessDenied_vi from "./assets/languages/vi/accessDenied.json";

import home_cn from "./assets/languages/cn/home.json";
import agentBankAccount_cn from "./assets/languages/cn/agentBankAccount.json";
import balances_cn from "./assets/languages/cn/balances.json";
import changePassword_cn from "./assets/languages/cn/changePassword.json";
import dashboard_cn from "./assets/languages/cn/dashboard.json";
import deposit_cn from "./assets/languages/cn/deposit.json";
import notification_cn from "./assets/languages/cn/notification.json";
import member_cn from "./assets/languages/cn/member.json";
import message_cn from "./assets/languages/cn/message.json";
import profile_cn from "./assets/languages/cn/profile.json";
import report_cn from "./assets/languages/cn/report.json";
import settlement_cn from "./assets/languages/cn/settlement.json";
import fundout_cn from "./assets/languages/cn/fundout.json";
import signIn_cn from "./assets/languages/cn/signIn.json";
import users_cn from "./assets/languages/cn/users.json";
import common_cn from "./assets/languages/cn/common.json";
import header_cn from "./assets/languages/cn/header.json";
import accessDenied_cn from "./assets/languages/cn/accessDenied.json";

i18n
    .use(initReactI18next)
    .init({
        // debug: true,

        whitelist: ['vi', 'en', 'cn'],
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                home: home_en,
                agentBankAccount: agentBankAccount_en,
                balances: balances_en,
                changePassword: changePassword_en,
                dashboard: dashboard_en,
                deposit: deposit_en,
                inbox: notification_en,
                member: member_en,
                message: message_en,
                profile: profile_en,
                report: report_en,
                settlement: settlement_en,
                signIn: signIn_en,
                users: users_en,
                common: common_en,
                header: header_en,
                fundout: fundout_en,
                accessDenied: accessDenied_en
            },
            vi: {
                home: home_vi,
                agentBankAccount: agentBankAccount_vi,
                balances: balances_vi,
                changePassword: changePassword_vi,
                dashboard: dashboard_vi,
                deposit: deposit_vi,
                notification: notification_vi,
                member: member_vi,
                message: message_vi,
                profile: profile_vi,
                report: report_vi,
                settlement: settlement_vi,
                signIn: signIn_vi,
                users: users_vi,
                common: common_vi,
                header: header_vi,
                fundout: fundout_vi,
                accessDenied: accessDenied_vi
            },
            cn: {
                home: home_cn,
                agentBankAccount: agentBankAccount_cn,
                balances: balances_cn,
                changePassword: changePassword_cn,
                dashboard: dashboard_cn,
                deposit: deposit_cn,
                notification: notification_cn,
                member: member_cn,
                message: message_cn,
                profile: profile_cn,
                report: report_cn,
                settlement: settlement_cn,
                signIn: signIn_cn,
                users: users_cn,
                common: common_cn,
                header: header_cn,
                fundout: fundout_cn,
                accessDenied: accessDenied_cn
            }
        },
        react: {
            wait: true
        }
    });


export default i18n;
