import {fromJS} from "immutable";
import {ReduxActionTypes} from './action-type';

const initialState: any = fromJS({
    pageLoading: false,
    regionList: [],
    stateList: [],
})

export default function (state = initialState, action: any) {
    switch (action.type) {
        case ReduxActionTypes.SET_PAGE_LOADING:
            return state.set('pageLoading', action.value)
        case ReduxActionTypes.SET_REGION:
            return state.set('regionList', action.value)
        case ReduxActionTypes.SET_STATE:
            return state.set('stateList', action.value)
        default:
            return state;
    }
}
