import RootState from '../rootState';

export const stateAccountSelectors = {
    getState: (rootState: RootState): any => rootState.account,

    getAuthenticationStatus(rootState: RootState): boolean {
        return this.getState(rootState).get('isAuthenticated');
    },

    getUserInfo(rootState: RootState): any {
        return this.getState(rootState).get('userInfo');
    },

    getLoginRequestStatus(rootState: RootState): boolean {
        return this.getState(rootState).get('isLoginRequest');
    },

    getLoginFailMessage(rootState: RootState): string {
        return this.getState(rootState).get('loginFailMessage');
    },

    getUserNotification(rootState: RootState): [] {
        return this.getState(rootState).get('notification');
    },

    getOpenPopUpViewNotification(rootState: RootState): boolean {
        return this.getState(rootState).get('isOpenPopUpViewNotification');
    },

    getDataPopUpNotification(rootState: RootState): [] {
        return this.getState(rootState).get('dataNotification');
    },

    getOpenPopUpChangePasswordSettlement(rootState: RootState): boolean {
        return this.getState(rootState).get('isOpenPopUpChangePasswordSettlement');
    },

    getOpenPopUpEnableSettlementPassword(rootState: RootState): boolean {
        return this.getState(rootState).get('isOpenPopUpEnableSettlementPassword');
    },

    getOpenPopUpDisableSettlementPassword(rootState: RootState): boolean {
        return this.getState(rootState).get('isOpenPopUpDisableSettlementPassword');
    },

    getQrCode2FA(rootState: RootState): any {
        return this.getState(rootState).get('qrCode');
    },

    getOpenPopUpEnable2FA(rootState: RootState): boolean {
        return this.getState(rootState).get('isOpenPopUpEnable2FA');
    },

    getOpenPopUpDisable2FA(rootState: RootState): boolean {
        return this.getState(rootState).get('isOpenPopUpDisable2FA');
    },

    getVerification2FALogin(rootState: RootState): boolean {
        return this.getState(rootState).get('isVerification2FALogin');
    },
}
