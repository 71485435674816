export const SagaActionTypes = {
    FETCH_REPORT_FUND_TRANSFER_LIST: 'FETCH_REPORT_FUND_TRANSFER_LIST',
    FETCH_REPORT_FUND_OUT_LIST: 'FETCH_REPORT_FUND_OUT_LIST',
    FETCH_REPORT_TOTAL_LIST: 'FETCH_REPORT_TOTAL_LIST',
};

export const ReduxActionTypes = {
    SET_REPORT_FUND_TRANSFER_LIST: 'SET_REPORT_FUND_TRANSFER_LIST',
    SET_REPORT_FUND_OUT_LIST: 'SET_REPORT_FUND_OUT_LIST',
    SET_REPORT_TOTAL_LIST: 'SET_REPORT_TOTAL_LIST',
};
