import React from "react";
import {Route} from "react-router-dom";
import {connect} from "react-redux";
import RootState from "../../../modules/rootState";
import {stateAccountSelectors} from "../../../modules/accounts";

export interface StateFromProps {
     isAuthenticated: boolean
}

export interface DispatchFromProps {
}

type Props = any | StateFromProps & DispatchFromProps;

type State = {};

class PublicRoute extends React.Component<Props, State> {
    render() {
        const {component: Component, ...rest} = this.props

        return (
            <Route
                {...rest}
                render={props =>  <Component {...props}/>}
            />
        )
    }
}

const mapStateToProps = (rootState: RootState) => ({
    isAuthenticated: stateAccountSelectors.getAuthenticationStatus(rootState),
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute)
