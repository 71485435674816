export const SagaActionTypes = {
    FETCH_TOTAL_DEPOSIT: 'FETCH_TOTAL_DEPOSIT',
    FETCH_TOTAL_SETTLEMENT_FUNDOUT: 'FETCH_TOTAL_SETTLEMENT_FUNDOUT',
    FETCH_NUMBER_OF_TRANSACTION: 'FETCH_NUMBER_OF_TRANSACTION',
    FETCH_DEPOSIT_SUCCESS_RATE: 'CREATE_DEPOSIT_SUCCESS_RATE',
    FETCH_TOTAL_DEPOSIT_CHART: 'FETCH_TOTAL_DEPOSIT_CHART',
    FETCH_TOTAL_SETTLEMENT_FUNDOUT_CHART: 'FETCH_TOTAL_SETTLEMENT_FUNDOUT_CHART',
    FETCH_NUMBER_OF_TRANSACTION_CHART: 'FETCH_NUMBER_OF_TRANSACTION_CHART',
    FETCH_DEPOSIT_SUCCESS_RATE_CHART: 'FETCH_DEPOSIT_SUCCESS_RATE_CHART',
};

export const ReduxActionTypes = {
    SET_TOTAL_DEPOSIT: 'SET_TOTAL_DEPOSIT',
    SET_TOTAL_SETTLEMENT_FUNDOUT: 'SET_TOTAL_SETTLEMENT_FUNDOUT',
    SET_NUMBER_OF_TRANSACTION: 'SET_NUMBER_OF_TRANSACTION',
    SET_DEPOSIT_SUCCESS_RATE: 'SET_DEPOSIT_SUCCESS_RATE',
    SET_TOTAL_DEPOSIT_CHART: 'SET_TOTAL_DEPOSIT_CHART',
    SET_TOTAL_SETTLEMENT_FUNDOUT_CHART: 'SET_TOTAL_SETTLEMENT_FUNDOUT_CHART',
    SET_NUMBER_OF_TRANSACTION_CHART: 'SET_NUMBER_OF_TRANSACTION_CHART',
    SET_DEPOSIT_SUCCESS_RATE_CHART: 'SET_DEPOSIT_SUCCESS_RATE_CHART',
};
