import {ReduxActionTypes, SagaActionTypes} from './action-type'

//==============MIDDLE WARE======================

export const sagaActions = {
    fetchBalanceLogList: (object): any => ({
        type: SagaActionTypes.FETCH_BALANCE_LOG_LIST,
        object
    }),

}

//===============REDUX===================

export const reduxActions = {

    setBalanceLogList: (listBalanceLog): any => ({
        type: ReduxActionTypes.SET_BALANCE_LOG_LIST,
        listBalanceLog
    }),

}

