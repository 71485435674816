export const SagaActionTypes = {
    FETCH_BANK_ACCOUNT_MEMBER: 'FETCH_BANK_ACCOUNT_MEMBER',
    FETCH_ACTIVE_BANK_ACCOUNT_MEMBER: 'FETCH_ACTIVE_BANK_ACCOUNT_MEMBER',
    FETCH_BANK_LIST: 'FETCH_BANK_LIST',
    CREATE_BANK_ACCOUNT: 'CREATE_BANK_ACCOUNT',
    DELETE_BANK_ACCOUNT: 'DELETE_BANK_ACCOUNT',
    UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT'
};

export const ReduxActionTypes = {
    SET_BANK_ACCOUNT_MEMBER: 'SET_BANK_ACCOUNT_MEMBER',
    SET_ACTIVE_BANK_ACCOUNT_MEMBER: 'SET_ACTIVE_BANK_ACCOUNT_MEMBER',
    SET_BANK_LIST: 'SET_BANK_LIST',
};
