export const SagaActionTypes = {
    FETCH_DEPOSIT_LIST: 'FETCH_DEPOSIT_LIST',
    FETCH_SETTLEMENT_HISTORY_LIST: 'FETCH_SETTLEMENT_HISTORY_LIST',
    CREATE_SETTLEMENT: 'CREATE_SETTLEMENT',
    FETCH_FUND_OUT_HISTORY_LIST: 'FETCH_FUND_OUT_HISTORY_LIST',
    CREATE_FUND_OUT: 'CREATE_FUND_OUT',
    FETCH_AVAILABLE_BANK_LIST: 'FETCH_AVAILABLE_BANK_LIST',
    REJECT_FUND_OUT: 'REJECT_FUND_OUT'
};

export const ReduxActionTypes = {
    SET_DEPOSIT_LIST: 'SET_DEPOSIT_LIST',
    SET_SETTLEMENT_HISTORY_LIST: 'SET_SETTLEMENT_HISTORY_LIST',
    SET_FUND_OUT_HISTORY_LIST: 'SET_FUND_OUT_HISTORY_LIST',
    TOGGLE_OPEN_POPUP_TRANSFER_WALLET: 'TOGGLE_OPEN_POPUP_TRANSFER_WALLET',
    HIDE_CONFIRM_USDT_SCREEN: 'HIDE_CONFIRM_USDT_SCREEN',
    SET_PENDING_DEPOSIT: 'SET_PENDING_DEPOSIT',
    SET_EXPIRED_DEPOSIT: 'SET_EXPIRED_DEPOSIT',
    SET_AVAILABLE_BANK_LIST: 'SET_AVAILABLE_BANK_LIST',
    SET_FAILED_MESSAGE_AVAILABLE_BANK_LIST: 'SET_FAILED_MESSAGE_AVAILABLE_BANK_LIST',
    SET_CONFIRM_USDT_DATA: 'SET_CONFIRM_USDT_DATA'
};
