import {fromJS} from 'immutable';
import {ReduxActionTypes} from './action-type';

const initialState: any = fromJS({
    totalDeposit: null,
    totalSettlementFundOut: null,
    numberOfTransaction: null,
    depositSuccessRate: null,
    listTotalDepositChart: [],
    listTotalSettlementFundOutChart: [],
    listNumberOfTransactionChart: [],
    listDepositSuccessRateChart: []

})

export default function (state = initialState, action: any) {
    switch (action.type) {

        case ReduxActionTypes.SET_TOTAL_DEPOSIT:
            return state.set('totalDeposit', action.totalDeposit)

        case ReduxActionTypes.SET_TOTAL_SETTLEMENT_FUNDOUT:
            return state.set('totalSettlementFundOut', action.totalSettlementFundOut)

        case ReduxActionTypes.SET_NUMBER_OF_TRANSACTION:
            return state.set('numberOfTransaction', action.numberOfTransaction)

        case ReduxActionTypes.SET_DEPOSIT_SUCCESS_RATE:
            return state.set('depositSuccessRate', action.depositSuccessRate)


        //chart
        case ReduxActionTypes.SET_TOTAL_DEPOSIT_CHART:
            return state.set('listTotalDepositChart', action.listTotalDepositChart)

        case ReduxActionTypes.SET_TOTAL_SETTLEMENT_FUNDOUT_CHART:
            return state.set('listTotalSettlementFundOutChart', action.listTotalSettlementFundOutChart)

        case ReduxActionTypes.SET_NUMBER_OF_TRANSACTION_CHART:
            return state.set('listNumberOfTransactionChart', action.listNumberOfTransactionChart)

        case ReduxActionTypes.SET_DEPOSIT_SUCCESS_RATE_CHART:
            return state.set('listDepositSuccessRateChart', action.listDepositSuccessRateChart)

        default:
            return state;
    }
}
