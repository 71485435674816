import {fromJS} from 'immutable';
import {ReduxActionTypes} from './action-type';

const initialState: any = fromJS({
    listBalanceLog: [],
})

export default function (state = initialState, action: any) {
    switch (action.type) {

        case ReduxActionTypes.SET_BALANCE_LOG_LIST:
            return state.set('listBalanceLog', action.listBalanceLog)

        default:
            return state;
    }
}
