import React from 'react'
import ReactDOM from 'react-dom';
import App from './App';
import ConfigureStore from './store';
import {Provider} from 'react-redux';
import {PersistGate} from "redux-persist/integration/react";
import {Fallback} from "./components/layouts/FallBack";
import i18n from './i18n';
import {I18nextProvider} from 'react-i18next';
import {BrowserRouter} from "react-router-dom";

const {store, history, persist} = ConfigureStore()

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persist} loading={<Fallback/>}>
            <BrowserRouter basename={'/'}>
                <I18nextProvider i18n={i18n}>
                    <App/>
                </I18nextProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
