import {fromJS} from 'immutable';
import {ReduxActionTypes} from './action-type';

const initialState: any = fromJS({
    walletType: '',
    pendingDeposit: null,
    expiredDeposit: null,
    availableBankListFailedMessage: null,
    isTransferWalletPopupOpen: false,
    isShowConfirmUSDT: false,
    confirmUSDTData: null,
    depositList: [],
    settlementHistoryList: [],
    fundOutHistoryList: [],
    availableBankList: []
})

export default function (state = initialState, action: any) {
    switch (action.type) {
        //deposit
        case ReduxActionTypes.SET_DEPOSIT_LIST:
            return state.set('depositList', action.depositList)
        case ReduxActionTypes.SET_AVAILABLE_BANK_LIST:
            return state.set('availableBankList', action.availableBankList)
        case ReduxActionTypes.SET_FAILED_MESSAGE_AVAILABLE_BANK_LIST:
            return state.set('availableBankListFailedMessage', action.availableBankListFailedMessage)
        case ReduxActionTypes.SET_PENDING_DEPOSIT:
            return state.set('pendingDeposit', action.deposit)
        case ReduxActionTypes.SET_EXPIRED_DEPOSIT:
            return state.set('expiredDeposit', action.deposit)

        //settlement
        case ReduxActionTypes.SET_SETTLEMENT_HISTORY_LIST:
            return state.set('settlementHistoryList', action.settlementHistoryList)

        //fund out
        case ReduxActionTypes.SET_FUND_OUT_HISTORY_LIST:
            return state.set('fundOutHistoryList', action.fundOutHistoryList)

        //transfer wallet
        case ReduxActionTypes.TOGGLE_OPEN_POPUP_TRANSFER_WALLET:
            return state
                .set('walletType', action.walletType)
                .set('isTransferWalletPopupOpen', action.isTransferWalletPopupOpen)

        //transfer usdt
        case ReduxActionTypes.SET_CONFIRM_USDT_DATA:
            return state
                .set('isShowConfirmUSDT', true)
                .set('confirmUSDTData', action.data)
        case ReduxActionTypes.HIDE_CONFIRM_USDT_SCREEN:
            return state
                .set('isShowConfirmUSDT', false)
                .set('confirmUSDTData', null)
        default:
            return state;
    }
}
