import {useHistory, useParams} from "react-router-dom"
import i18n from "../../i18n";

export const LangRoutes = (props) => {
    const history = useHistory()
    const params = useParams()

    let locale:string = localStorage.getItem('i18nextLng')

    // Language route
    const supportLang:string[] = ["vi", "en", "cn"]
    const path:string = history.location.pathname
    const urlLangParam = (params as any).lang

    if (!supportLang.includes(urlLangParam)) {
        window.location.replace(`/${locale}${path}`)
    } else {
        localStorage.setItem('i18nextLng', urlLangParam)
        i18n.changeLanguage(urlLangParam).then()
        return props.children
    }
}