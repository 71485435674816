import {SagaIterator} from 'redux-saga';
import {all} from 'redux-saga/effects';
import accountSaga from './accounts/saga';
import commonSaga from './common/saga';
import transactionSaga from './transactions/saga';
import bankSaga from './bank/saga';
import memberSaga from './members/saga';
import dashboardSaga from './dashboard/saga';
import reportSaga from './reports/saga';
import balancelogSaga from './balancelog/saga';

export default function* rootSaga(): SagaIterator {
    yield all([
        ...commonSaga,
        ...accountSaga,
        ...transactionSaga,
        ...bankSaga,
        ...memberSaga,
        ...dashboardSaga,
        ...reportSaga,
        ...balancelogSaga
    ]);
}

