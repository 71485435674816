import axios from 'axios'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/`,
    params: {
        t: new Date().getTime()
    }
});

instance.interceptors.request.use(function (request: any) {

    const currentLang = localStorage.getItem('i18nextLng')
    const token = localStorage.getItem('token')
    if (token) {
        request.headers = {
            ...request.headers,
            'Authorization': `Bearer ${token}`
        }
    }
    if (currentLang) {
        let lang = currentLang === 'vi' ? "vi-VN" : (currentLang === 'en' ? "en-US" : "zh-CN")
        request.headers = {
            ...request.headers,
            'Accept-Language': lang
        }
    }

    return request;
}, function (error: any) {
    return Promise.reject(error)
});

instance.interceptors.response.use(function (response: any) {
    return response;
}, function (error: any) {

    const {status} = error.response
    const token = localStorage.getItem('token')
    if (status === 498 && token) {
        window['showTokenExpiredPopup']()
    }
    return Promise.reject(error)
});

export default instance

