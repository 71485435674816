export const SagaActionTypes = {
    LOGIN: 'LOGIN',
    FETCH_USER_PROFILE: 'FETCH_USER_PROFILE',
    UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_SETTLEMENT: 'CHANGE_PASSWORD_SETTLEMENT',
    CHECK_STATUS_SETTLEMENT_PASSWORD: 'CHECK_STATUS_SETTLEMENT_PASSWORD',
    FETCH_USER_NOTIFICATION: 'FETCH_USER_NOTIFICATION',
    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
    TRANSFER_WALLET: 'TRANSFER_WALLET',
    GET_QRCODE_2FA: 'GET_QRCODE_2FA',
    VERIFICATION_QRCODE_2FA: 'VERIFICATION_QRCODE_2FA',
    VERIFICATION_LOGIN: 'VERIFICATION_LOGIN',
    EXCHANGE_USDT: 'EXCHANGE_USDT'
};

export const ReduxActionTypes = {
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    LOGIN: 'LOGIN',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    SET_USER_INFO: 'SET_USER_INFO',
    SET_LOGIN_FAIL_MESSAGE: 'SET_LOGIN_FAIL_MESSAGE',
    SET_USER_NOTIFICATION: 'SET_USER_NOTIFICATION',
    TOGGLE_OPEN_POPUP_VIEW_NOTIFICATION: 'TOGGLE_OPEN_POPUP_VIEW_NOTIFICATION',
    SET_DATA_POPUP_NOTIFICATION: 'SET_DATA_POPUP_NOTIFICATION',
    TOGGLE_OPEN_CHANGE_PASSWORD_SETTLEMENT: 'TOGGLE_OPEN_CHANGE_PASSWORD_SETTLEMENT',
    TOGGLE_OPEN_POPUP_ENABLE_SETTLEMENT_PASSWORD: 'TOGGLE_OPEN_POPUP_ENABLE_SETTLEMENT_PASSWORD',
    TOGGLE_OPEN_POPUP_DISABLE_SETTLEMENT_PASSWORD: 'TOGGLE_OPEN_POPUP_DISABLE_SETTLEMENT_PASSWORD',
    SET_QRCODE_2FA: 'SET_QRCODE_2FA',
    TOGGLE_OPEN_POPUP_ENABLE_2FA: 'TOGGLE_OPEN_POPUP_ENABLE_2FA',
    TOGGLE_OPEN_POPUP_DISABLE_2FA: 'TOGGLE_OPEN_POPUP_DISABLE_2FA',
    TOGGLE_SET_VERIFICATION_2FA_LOGIN: 'TOGGLE_SET_VERIFICATION_2FA_LOGIN',
};

export const MiddleWareActionTypes = {
    CONNECT_SIGNALR: 'CONNECT_SIGNALR'
}
