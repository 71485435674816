import {toast} from "react-toastify";
import moment from "moment";

export const numberWithCommas = (x) => {
    if (x !== undefined && x !== null) {
        return x.toLocaleString('en-US', {minimumFractionDigits: 0})
    }
    return ''
}

export const numberWithCommas2Digits = (x) => {
    if (x !== undefined && x !== null) {
        return x.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    }
    return ''
}

export const numberWithCommasChart = (x) => {
    let val: string | number = Math.abs(x);

    if (val >= 10 ** 3 && val < 10 ** 6) {
        val = (val / 1000).toFixed(0) + ' N'
    } else if (val >= 10 ** 6 && val < 10 ** 9) {
        val = (val / 1000000).toFixed(0) + ' Tr'
    } else if (val >= 10 ** 9) {
        val = (val / 1000000000).toFixed(0) + ' T'
    }

    return val
}

export const copyToClipBoard = (elementId) => {
    let el = document.createElement('textarea')

    if (elementId === 'amount') {
        let amount = (document.getElementById(elementId) as any).value
        el.value = amount.split(" ")[0].replace(/,/g, '')
    } else if (elementId === 'bank_acc') {
        el.value = (document.getElementById(elementId) as any).value  || (document.getElementById(elementId) as any).textContent
    } else {
        el.value = (document.getElementById(elementId) as any).value
    }

    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)

    toast.success("Sao chép thành công!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    })
}

export const getRandomColorFromName = (name) => {
    let firstAlphabet = ""
    let r = 16
    let g = 8
    let b = 225
    if (name !== undefined) {
        firstAlphabet = name.charAt(0).toLowerCase();
        let asciiCode = firstAlphabet.charCodeAt(0);
        let colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

        let num = Math.round(0xffffff * parseInt(colorNum));
        // eslint-disable-next-line no-mixed-operators
        r = num >> 16 & 255;
        // eslint-disable-next-line no-mixed-operators
        g = num >> 8 & 255;
        b = num & 255;
    }
    return {
        color: 'rgb(' + r + ', ' + g + ', ' + b + ', 0.8)',
        character: firstAlphabet.toUpperCase()
    }
}

export const formatDatetime = (date) => {
    let newDate = moment(date)
    return newDate.format('YYYY-MM-DD HH:mm:ss')
}
