import React from 'react';
import {Redirect, Route} from "react-router-dom";

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import RootState from "./modules/rootState";
import {connect} from "react-redux";
import {ToastContainer} from "react-toastify";
import {URLS} from "./configs/routes_url";
import {LangRoutes} from "./routes/LangRoutes";
import AgentRoutes from "./routes/LangRoutes/AgentRoutes";
import MemberRoutes from "./routes/LangRoutes/MemberRoutes";
import StartFireBase from "./configs/firebase";
import {ref, onValue, onDisconnect} from "firebase/database";


// import css
import "../src/assets/scss/_variables.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/css/main.css";
import "../src/assets/css/icons.css";
import "../src/assets/css/animate.css";
import "../src/assets/css/custom.css";
import {stateAccountSelectors} from "./modules/accounts";
import API from "./api";
import {API_URLS} from "./configs/api_url";


type StateFromProps = {
    isAuthenticated: boolean
    userInfo: any
}
type DispatchFromProps = {}
type OwnProps = {}

type Props = StateFromProps & OwnProps & DispatchFromProps;
type State = {
    db: any
};

class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            db: StartFireBase()
        }

        // Set default locale : Vi
        let locale = localStorage.getItem('i18nextLng')
        if (!locale) {
            locale = "vi"
            localStorage.setItem('i18nextLng', locale)
        }
    }

    async UNSAFE_componentWillMount() {
        const searchParams = new URLSearchParams(window.location.search)
        let company_number = searchParams.get('company_number') || '';

        const faviconElement = document.getElementById("favicon");
        faviconElement.setAttribute("href", `${process.env.REACT_APP_API_URL + "/" + API_URLS.GET_AGENT_FAVICON + "?company_number=" + company_number}`);
    }

    componentDidMount() {
        const {db} = this.state

        const connectedRef = ref(db, ".info/connected");
        onValue(connectedRef, (snap) => {
            if (snap.val() === true) {
                console.log("Connected websocket !");
            }
        });

        // const presenceRef = ref(db, "disconnected");
        // onDisconnect(presenceRef).set("Disconnected websocket !");
    }

    render() {
        const locale = localStorage.getItem('i18nextLng')
        const {isAuthenticated, userInfo} = this.props

        return (
            <>
                <ToastContainer style={{zIndex: 9999999999999}}/>

                {process.env.REACT_APP_ROLE === "AGENT" && (
                    <>
                        <Route exact path="/">
                            {
                                !isAuthenticated ?
                                    <Redirect to={`/${locale}${URLS.SIGN_IN}`}/>
                                    : (!userInfo.is_agent_access_to_report ?
                                        <Redirect to={`/${locale}${URLS.DASHBOARD}`}/> : (
                                            <Redirect to={`/${locale}${URLS.REPORT_FUND_SETTLEMENT}`}/>
                                        ))
                            }
                        </Route>
                        <Route path="/:lang">
                            <LangRoutes>
                                <AgentRoutes/>
                            </LangRoutes>
                        </Route>
                    </>
                )}

                {process.env.REACT_APP_ROLE === "MEMBER" && (
                    <>
                        <Route exact path="/">
                            <Redirect to={`/${locale}${URLS.BANK_TRANSFER}`}/>
                        </Route>
                        <Route path="/:lang">
                            <LangRoutes>
                                <MemberRoutes/>
                            </LangRoutes>
                        </Route>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (rootState: RootState) => ({
    isAuthenticated: stateAccountSelectors.getAuthenticationStatus(rootState),
    userInfo: stateAccountSelectors.getUserInfo(rootState)
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App) as any
