import {fromJS} from 'immutable';
import {ReduxActionTypes} from './action-type';

const initialState: any = fromJS({
    listBankAccountMember: [],
    listActiveBankAccountMember: [],
    listBank: [],
})

export default function (state = initialState, action: any) {
    switch (action.type) {

        case ReduxActionTypes.SET_ACTIVE_BANK_ACCOUNT_MEMBER:
            return state.set('listActiveBankAccountMember', action.listActiveBankAccountMember)

        case ReduxActionTypes.SET_BANK_ACCOUNT_MEMBER:
            return state.set('listBankAccountMember', action.listBankAccountMember)

        case ReduxActionTypes.SET_BANK_LIST:
            return state.set('listBank', action.listBank)

        default:
            return state;
    }
}
