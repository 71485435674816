import {MiddleWareActionTypes, ReduxActionTypes, SagaActionTypes} from './action-type'

//==============MIDDLE WARE======================
export const middlewareActions = {
    connectSignalR: (userName: any) => ({
        type: MiddleWareActionTypes.CONNECT_SIGNALR,
        userName
    })
}
//==============SAGA======================

export const sagaActions = {
    loginRequest: (loginObject: any) => ({
        type: SagaActionTypes.LOGIN,
        loginObject
    }),

    changePassword: (changePasswordObject: any) => ({
        type: SagaActionTypes.CHANGE_PASSWORD,
        changePasswordObject
    }),

    changePasswordSettlement: (object: any) => ({
        type: SagaActionTypes.CHANGE_PASSWORD_SETTLEMENT,
        object
    }),

    fetchUserProfile: (): any => ({
        type: SagaActionTypes.FETCH_USER_PROFILE,
    }),

    updateUserProfile: (profileObject: any) => ({
        type: SagaActionTypes.UPDATE_USER_PROFILE,
        profileObject
    }),

    fetchUserNotification: () => ({
        type: SagaActionTypes.FETCH_USER_NOTIFICATION
    }),

    updateUserNotification: (notificationObject: any) => ({
        type: SagaActionTypes.UPDATE_NOTIFICATION,
        notificationObject
    }),

    transferWallet: (amount, walletType): any => ({
        type: SagaActionTypes.TRANSFER_WALLET,
        amount,
        walletType
    }),

    checkStatusSettlementPassword: (object: any) => ({
        type: SagaActionTypes.CHECK_STATUS_SETTLEMENT_PASSWORD,
        object
    }),

    getQrCode2FA: () => ({
        type: SagaActionTypes.GET_QRCODE_2FA,
    }),

    verificationQrCode2FA: (object: any) => ({
        type: SagaActionTypes.VERIFICATION_QRCODE_2FA,
        object
    }),

    verificationLogin: (loginObject: any) => ({
        type: SagaActionTypes.VERIFICATION_LOGIN,
        loginObject
    }),
  
    exchangeUSDT: (exchangeId): any => ({
        type: SagaActionTypes.EXCHANGE_USDT,
        exchangeId
    }),
}


//===============REDUX===================

export const reduxActions = {
    loginSuccess: (token): any => ({
        type: ReduxActionTypes.LOGIN_SUCCESS,
        token
    }),

    loginFail: (message): any => ({
        type: ReduxActionTypes.LOGIN_FAIL,
        message
    }),

    setUserInfo: (userObject): any => ({
        type: ReduxActionTypes.SET_USER_INFO,
        userObject
    }),

    loginRequest: (): any => ({
        type: ReduxActionTypes.LOGIN_REQUEST,
    }),

    setUserNotification: (notification): any => ({
        type: ReduxActionTypes.SET_USER_NOTIFICATION,
        notification
    }),

    toggleOpenPopupViewNotification: (toggleStatus = false): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_POPUP_VIEW_NOTIFICATION,
        toggleStatus
    }),

    setDataPopUpNotification: (dataNotification): any => ({
        type: ReduxActionTypes.SET_DATA_POPUP_NOTIFICATION,
        dataNotification
    }),

    setToggleOpenPopupChangePasswordSettlement: (toggleStatus = false): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_CHANGE_PASSWORD_SETTLEMENT,
        toggleStatus
    }),

    setToggleOpenPopupEnableSettlementPassword: (toggleStatus = false): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_POPUP_ENABLE_SETTLEMENT_PASSWORD,
        toggleStatus
    }),

    setToggleOpenPopupDisableSettlementPassword: (toggleStatus = false): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_POPUP_DISABLE_SETTLEMENT_PASSWORD,
        toggleStatus
    }),

    setQrCode2FA: (qrCode): any => ({
        type: ReduxActionTypes.SET_QRCODE_2FA,
        qrCode
    }),

    setToggleOpenPopupEnable2FA: (toggleStatus): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_POPUP_ENABLE_2FA,
        toggleStatus
    }),

    setToggleOpenPopupDisable2FA: (toggleStatus): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_POPUP_DISABLE_2FA,
        toggleStatus
    }),

    setToggleVerification2FALogin: (toggleStatus): any => ({
        type: ReduxActionTypes.TOGGLE_SET_VERIFICATION_2FA_LOGIN,
        toggleStatus
    }),
}

