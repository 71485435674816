import React from 'react';
import {Row} from "react-bootstrap";
import {WithTranslation, withTranslation} from "react-i18next";
import {RouteComponentProps} from "react-router-dom";

export type OwnProps = RouteComponentProps<{}>;
type Props = WithTranslation
type State = {};

class NotFound extends React.Component<Props, State> {
    render() {
        const {t} = this.props
        return (
            <Row>
                <div className="main-error-wrapper wrapper-1 page-h">
                    <h1 className="">404<span className="tx-20">{t('error')}</span></h1>
                    <h2 className="">{t('Page not found')}</h2>
                    <h2 className="">{t("Oopps")} {t("The page you were looking for doesn't exist")}</h2>
                    <h6 className="">{t('You may have entered the wrong address or have not logged into your account')}</h6>
                    <button onClick={() => window.location.replace(`/`)}
                            className="btn btn-main-primary">{t('Go To Homepage')}</button>
                </div>
            </Row>
        );
    }
}

export default withTranslation('accessDenied')(NotFound);
