import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaActionTypes} from './action-type';
import {API_URLS} from "../../configs/api_url";
import {toast} from "react-toastify";
import {reduxActions} from "./action";
import API from '../../api/index';
import {reduxActions as reduxCommonAction} from '../common/action'

function* fetchReportFundTransferList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('start_date', action.object.startDate);
        formData.append('end_date', action.object.endDate);
        formData.append('search_type', action.object.searchType);

        const {data} = yield call(API.post, API_URLS.GET_REPORT_FUND_TRANSFER, formData, {headers: headers})
        yield put(reduxActions.setReportFundTransferList(data.result))

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* fetchReportFundOutList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('start_date', action.object.startDate);
        formData.append('end_date', action.object.endDate);
        formData.append('search_type', action.object.searchType);

        const {data} = yield call(API.post, API_URLS.GET_REPORT_FUND_OUT_LIST, formData, {headers: headers})
        yield put(reduxActions.setReportFundOutList(data.result))

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* fetchReportTotalList(action: any) {
    try {
        yield put(reduxCommonAction.makePageLoading(true))
        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('start_date', action.object.startDate);
        formData.append('end_date', action.object.endDate);
        formData.append('search_type', action.object.searchType);

        const {data} = yield call(API.post, API_URLS.GET_REPORT_TOTAL_LIST, formData, {headers: headers})
        yield put(reduxActions.setReportTotalList(data.result))

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}


const reportSaga = [
    takeLatest(SagaActionTypes.FETCH_REPORT_FUND_TRANSFER_LIST, fetchReportFundTransferList),
    takeLatest(SagaActionTypes.FETCH_REPORT_FUND_OUT_LIST, fetchReportFundOutList),
    takeLatest(SagaActionTypes.FETCH_REPORT_TOTAL_LIST, fetchReportTotalList),
]

export default reportSaga
