import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaActionTypes} from './action-type';
import {API_URLS} from "../../configs/api_url";
import {reduxActions} from "./action";
import API from '../../api/index';
import {numberWithCommas} from "../../utils";

function* fetchTotalDeposit() {
    try {
        const {data} = yield call(API.get, API_URLS.GET_TOTAL_DEPOSIT)
        let totalDepositFormat = numberWithCommas(parseFloat(data.result))
        yield put(reduxActions.setTotalDeposit(totalDepositFormat))
    } catch (e) {
        console.log(e);
        yield put(reduxActions.setTotalDeposit(0))
    }
}

function* fetchTotalSettlementFundOut() {
    try {
        const {data} = yield call(API.get, API_URLS.GET_TOTAL_SETTLEMENT_FUNDOUT)

        let totalSettlementFundOutFormat = numberWithCommas(parseFloat(data.result))
        yield put(reduxActions.setTotalSettlementFundOut(totalSettlementFundOutFormat))

    } catch (e) {
        console.log(e);
        yield put(reduxActions.setTotalSettlementFundOut(0))
    }
}

function* fetchNumberOfTransaction() {
    try {
        const {data} = yield call(API.get, API_URLS.GET_NUMBER_OF_TRANSACTION)
        yield put(reduxActions.setNumberOfTransaction(data.result))

    } catch (e) {
        console.log(e);
        yield put(reduxActions.setNumberOfTransaction(0))
    }
}

function* fetchDepositSuccessRate() {
    try {
        const {data} = yield call(API.get, API_URLS.GET_DEPOSIT_SUCCESS_RATE)
        yield put(reduxActions.setDepositSuccessRate(data.result))

    } catch (e) {
        console.log(e);
        yield put(reduxActions.setDepositSuccessRate(0))
    }
}

function* fetchTotalDepositChart() {
    try {
        const {data} = yield call(API.get, API_URLS.GET_TOTAL_DEPOSIT_CHART)
        yield put(reduxActions.setTotalDepositChart(data.result[0]))
    } catch (e) {
        console.log(e);
    }
}

function* fetchTotalSettlementFundOutChart() {
    try {
        const {data} = yield call(API.get, API_URLS.GET_TOTAL_SETTLEMENT_FUNDOUT_CHART)

        yield put(reduxActions.setTotalSettlementFundOutChart(data.result[0]))

    } catch (e) {
        console.log(e);
    }
}
function* fetchNumberOfTransactionChart() {
    try {
        const {data} = yield call(API.get, API_URLS.GET_NUMBER_OF_TRANSACTION_CHART)

        yield put(reduxActions.setNumberOfTransactionChart(data.result[0]))

    } catch (e) {
        console.log(e);
    }
}

function* fetchDepositSuccessRateChart() {
    try {
        const {data} = yield call(API.get, API_URLS.GET_DEPOSIT_SUCCESS_RATE_CHART)
        yield put(reduxActions.setDepositSuccessRateChart(data.result[0]))

    } catch (e) {
        console.log(e);
    }
}

const dashboardSaga = [
    takeLatest(SagaActionTypes.FETCH_TOTAL_DEPOSIT, fetchTotalDeposit),
    takeLatest(SagaActionTypes.FETCH_TOTAL_SETTLEMENT_FUNDOUT, fetchTotalSettlementFundOut),
    takeLatest(SagaActionTypes.FETCH_NUMBER_OF_TRANSACTION, fetchNumberOfTransaction),
    takeLatest(SagaActionTypes.FETCH_DEPOSIT_SUCCESS_RATE, fetchDepositSuccessRate),
    takeLatest(SagaActionTypes.FETCH_TOTAL_DEPOSIT_CHART, fetchTotalDepositChart),
    takeLatest(SagaActionTypes.FETCH_TOTAL_SETTLEMENT_FUNDOUT_CHART, fetchTotalSettlementFundOutChart),
    takeLatest(SagaActionTypes.FETCH_NUMBER_OF_TRANSACTION_CHART, fetchNumberOfTransactionChart),
    takeLatest(SagaActionTypes.FETCH_DEPOSIT_SUCCESS_RATE_CHART, fetchDepositSuccessRateChart),
]

export default dashboardSaga
