import {applyMiddleware, compose, createStore} from "redux"
import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history'
import {createTransform, persistReducer, persistStore} from 'redux-persist'

import immutableTransform from 'redux-persist-transform-immutable'
import createSagaMiddleware from 'redux-saga'
import rootSaga from "./modules/rootSaga"
import rootReducer from "./modules/rootReducer"
import signalRMiddleware from './modules/middleware/signalR'

import storage from 'redux-persist/lib/storage'

function ConfigureStore() {

    // Use Redux DevTools (use browser extension)
    const keyDevTools = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__';
    const composeEnhancers = (window as any)[keyDevTools] || compose;

    const sagaMiddleware = createSagaMiddleware();
    const history = createBrowserHistory();

    const middlewares = [sagaMiddleware, signalRMiddleware, routerMiddleware(history)]

    const setTransform = createTransform(
        // Save persist state to local storage
        (inboundState: any, key: any) => {

            let inboundJson = JSON.parse(inboundState)
            if (inboundJson.data) {
                // Add fields persist states here
                inboundJson.data = {
                    ...inboundJson.data
                }
                // Do not save state 'isAuthenticated'
                delete inboundJson.data.isAuthenticated
            }
            return JSON.stringify(inboundJson);
        },

        // Recover state from local storage
        (outboundState: any, key: any) => {

            let outboundJson = JSON.parse(outboundState)
            if (outboundJson.data) {
                outboundJson.data = {
                    ...outboundJson.data,
                    isLoginRequest: false,
                    loginFailMessage: '',
                    isOpenPopUpViewNotification: false,
                    isOpenPopUpChangePasswordSettlement: false,
                    isOpenPopUpEnableSettlementPassword: false,
                    isOpenPopUpDisableSettlementPassword: false,
                    isOpenPopUpEnable2FA: false,
                    isOpenPopUpDisable2FA: false,
                    isVerification2FALogin: false,
                    isAuthenticated: !!localStorage.getItem('token'),
                }
            }
            return JSON.stringify(outboundJson);
        },

        {whitelist: ['account']}
    );

    const persistConfig = {
        transforms: [immutableTransform(), setTransform],
        key: 'nonopay',
        storage,
        whitelist: ['account'],
    }
    const persistedReducer = persistReducer(persistConfig, rootReducer(history))

    const composedEnhancers = composeEnhancers(
        applyMiddleware(...middlewares),
    )

    const store = createStore(
        persistedReducer,
        composedEnhancers
    );

    const persist = persistStore(store)

    sagaMiddleware.run(rootSaga);


    return {
        store,
        history,
        persist,
    };
}

export default ConfigureStore;
