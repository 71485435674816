import {ReduxActionTypes, SagaActionTypes} from './action-type'

//==============SAGA======================

export const sagaActions = {
    redirect: (path, openTab = false): any => ({
        redirectTo: path,
        openTab: openTab,
        type: SagaActionTypes.REDIRECT
    }),

    redirectRouteWithLang: (path: string): any => ({
        redirectTo: path,
        type: SagaActionTypes.REDIRECT_ROUTE_WITH_LANG
    }),

    fetchRegionList: (): any => ({
        type: SagaActionTypes.FETCH_REGION_LIST,
    }),

    fetchStateList: (country): any => ({
        type: SagaActionTypes.FETCH_STATE_LIST,
        country
    }),
}


//===============REDUX===================

export const reduxActions = {
    makePageLoading: (value): any => ({
        type: ReduxActionTypes.SET_PAGE_LOADING,
        value
    }),

    setRegionList: (value): any => ({
         type: ReduxActionTypes.SET_STATE,
        value
    }),

    setStateList: (value): any => ({
         type: ReduxActionTypes.SET_REGION,
        value
    })
}


