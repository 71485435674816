import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaActionTypes} from './action-type';
import {API_URLS} from "../../configs/api_url";
import {reduxActions} from "./action";
import API from '../../api/index';
import {reduxActions as reduxCommonAction} from "../common/action";
import moment from "moment/moment";
import {toast} from "react-toastify";

function* fetchBalanceLogList(action: any) {
    try {

        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('start_date', action.object.startDate)
        formData.append('end_date', action.object.endDate)
        formData.append('wallet_type', action.object.walletType)
        formData.append('search_type', action.object.searchType)

        const {data} = yield call(API.post, API_URLS.GET_BALANCE_LOG_LIST, formData, {headers: headers})

        let balanceLogList = [...data.result]

        for (let i = 0; i < balanceLogList.length; i++) {
            let localTime = moment.utc(balanceLogList[i].transaction_date)
            balanceLogList[i].transaction_date = localTime.utcOffset("+08:00").format('YYYY-MM-DD HH:mm:ss')
        }
        yield put( reduxActions.setBalanceLogList(balanceLogList))

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}


const balanceLogSaga = [
    takeLatest(SagaActionTypes.FETCH_BALANCE_LOG_LIST, fetchBalanceLogList),
]

export default balanceLogSaga
