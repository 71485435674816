import {ReduxActionTypes, SagaActionTypes} from './action-type'

//==============MIDDLE WARE======================

export const sagaActions = {
    fetchBankAccountMember: (): any => ({
        type: SagaActionTypes.FETCH_BANK_ACCOUNT_MEMBER
    }),

    fetchActiveBankAccountMember: (): any => ({
        type: SagaActionTypes.FETCH_ACTIVE_BANK_ACCOUNT_MEMBER
    }),

    fetchBankList: (): any => ({
        type: SagaActionTypes.FETCH_BANK_LIST
    }),

    createBankAccount: (bankObject): any => ({
        type: SagaActionTypes.CREATE_BANK_ACCOUNT,
        bankObject
    }),

    deleteBankAccount: (bankObject): any => ({
        type: SagaActionTypes.DELETE_BANK_ACCOUNT,
        bankObject
    }),
    updateBankAccount: (bankObject): any => ({
        type: SagaActionTypes.UPDATE_BANK_ACCOUNT,
        bankObject
    })
}

//===============REDUX===================

export const reduxActions = {

    setBankAccountMember: (listBankAccountMember): any => ({
        type: ReduxActionTypes.SET_BANK_ACCOUNT_MEMBER,
        listBankAccountMember
    }),

    setActiveBankAccountMember: (listActiveBankAccountMember): any => ({
        type: ReduxActionTypes.SET_ACTIVE_BANK_ACCOUNT_MEMBER,
        listActiveBankAccountMember
    }),

    setBankList: (listBank): any => ({
        type: ReduxActionTypes.SET_BANK_LIST,
        listBank
    }),
}

