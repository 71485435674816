import {ReduxActionTypes, SagaActionTypes} from './action-type'

export const sagaActions = {
    fetchTotalDeposit: (): any => ({
        type: SagaActionTypes.FETCH_TOTAL_DEPOSIT
    }),

    fetchNumberOfTransaction: (): any => ({
        type: SagaActionTypes.FETCH_NUMBER_OF_TRANSACTION
    }),

    fetchTotalSettlementFundOut: (): any => ({
        type: SagaActionTypes.FETCH_TOTAL_SETTLEMENT_FUNDOUT
    }),

    fetchDepositSuccessRate: (): any => ({
        type: SagaActionTypes.FETCH_DEPOSIT_SUCCESS_RATE
    }),

    fetchTotalDepositChart: (): any => ({
        type: SagaActionTypes.FETCH_TOTAL_DEPOSIT_CHART
    }),

    fetchNumberOfTransactionChart: (): any => ({
        type: SagaActionTypes.FETCH_NUMBER_OF_TRANSACTION_CHART
    }),

    fetchTotalSettlementFundOutChart: (): any => ({
        type: SagaActionTypes.FETCH_TOTAL_SETTLEMENT_FUNDOUT_CHART
    }),

    fetchDepositSuccessRateChart: (): any => ({
        type: SagaActionTypes.FETCH_DEPOSIT_SUCCESS_RATE_CHART
    }),
}

//===============REDUX===================

export const reduxActions = {

    setTotalDeposit: (totalDeposit): any => ({
        type: ReduxActionTypes.SET_TOTAL_DEPOSIT,
        totalDeposit
    }),

    setTotalSettlementFundOut: (totalSettlementFundOut): any => ({
        type: ReduxActionTypes.SET_TOTAL_SETTLEMENT_FUNDOUT,
        totalSettlementFundOut
    }),

    setNumberOfTransaction: (numberOfTransaction): any => ({
        type: ReduxActionTypes.SET_NUMBER_OF_TRANSACTION,
        numberOfTransaction
    }),

    setDepositSuccessRate: (depositSuccessRate): any => ({
        type: ReduxActionTypes.SET_DEPOSIT_SUCCESS_RATE,
        depositSuccessRate
    }),

    setTotalDepositChart: (listTotalDepositChart): any => ({
        type: ReduxActionTypes.SET_TOTAL_DEPOSIT_CHART,
        listTotalDepositChart
    }),

    setTotalSettlementFundOutChart: (listTotalSettlementFundOutChart): any => ({
        type: ReduxActionTypes.SET_TOTAL_SETTLEMENT_FUNDOUT_CHART,
        listTotalSettlementFundOutChart
    }),

    setNumberOfTransactionChart: (listNumberOfTransactionChart): any => ({
        type: ReduxActionTypes.SET_NUMBER_OF_TRANSACTION_CHART,
        listNumberOfTransactionChart
    }),

    setDepositSuccessRateChart: (listDepositSuccessRateChart): any => ({
        type: ReduxActionTypes.SET_DEPOSIT_SUCCESS_RATE_CHART,
        listDepositSuccessRateChart
    }),
}

