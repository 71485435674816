import {ReduxActionTypes, SagaActionTypes} from './action-type'

//==============MIDDLE WARE======================
//==============SAGA======================

export const sagaActions = {
    fetchDepositList: (depositObject): any => ({
        type: SagaActionTypes.FETCH_DEPOSIT_LIST,
        depositObject
    }),

    fetchSettlementHistoryList: (settlementObject): any => ({
        type: SagaActionTypes.FETCH_SETTLEMENT_HISTORY_LIST,
        settlementObject
    }),

    createSettlement: (settlementObject): any => ({
        type: SagaActionTypes.CREATE_SETTLEMENT,
        settlementObject
    }),

    fetchFundOutHistoryList: (fundOutObject): any => ({
        type: SagaActionTypes.FETCH_FUND_OUT_HISTORY_LIST,
        fundOutObject
    }),

    createFundOut: (fundOutObject): any => ({
        type: SagaActionTypes.CREATE_FUND_OUT,
        fundOutObject
    }),

    fetchAvailableBankList: (): any => ({
        type: SagaActionTypes.FETCH_AVAILABLE_BANK_LIST,
    }),

    rejectFundOut: (id): any => ({
        type: SagaActionTypes.REJECT_FUND_OUT,
        id
    }),
}


//===============REDUX===================

export const reduxActions = {
    setDepositList: (depositList): any => ({
        type: ReduxActionTypes.SET_DEPOSIT_LIST,
        depositList
    }),

    setSettlementHistoryList: (settlementHistoryList): any => ({
        type: ReduxActionTypes.SET_SETTLEMENT_HISTORY_LIST,
        settlementHistoryList
    }),

    setFundOutHistoryList: (fundOutHistoryList): any => ({
        type: ReduxActionTypes.SET_FUND_OUT_HISTORY_LIST,
        fundOutHistoryList
    }),


    toggleOpenPopupTransferWallet: (walletType, isTransferWalletPopupOpen): any => ({
        type: ReduxActionTypes.TOGGLE_OPEN_POPUP_TRANSFER_WALLET,
        isTransferWalletPopupOpen,
        walletType
    }),

    hideIsShowConfirmUSDT: (): any => ({
        type: ReduxActionTypes.HIDE_CONFIRM_USDT_SCREEN
    }),

    setConfirmUSDTData: (data): any => ({
        type: ReduxActionTypes.SET_CONFIRM_USDT_DATA,
        data
    }),

    setAvailableBankList: (availableBankList): any => ({
        type: ReduxActionTypes.SET_AVAILABLE_BANK_LIST,
        availableBankList
    }),

    setAvailableBankListFailedMessage: (availableBankListFailedMessage): any => ({
        type: ReduxActionTypes.SET_FAILED_MESSAGE_AVAILABLE_BANK_LIST,
        availableBankListFailedMessage
    }),

    setPendingDeposit: (deposit): any => ({
        type: ReduxActionTypes.SET_PENDING_DEPOSIT,
        deposit
    }),

    setExpiredDeposit: (deposit): any => ({
        type: ReduxActionTypes.SET_EXPIRED_DEPOSIT,
        deposit
    }),

}

