import {call, put, takeLatest} from 'redux-saga/effects';
import {SagaActionTypes} from './action-type';
import {API_URLS} from "../../configs/api_url";
import {toast} from "react-toastify";
import {reduxActions, sagaActions} from "./action";
import API from '../../api/index';
import {reduxActions as reduxCommonAction} from "../common/action";

function* fetchActiveBankAccountMember(action: any) {
    try {
        const {data} = yield call(API.get, API_URLS.GET_ACTIVE_BANK_ACCOUNT_LIST)
        yield put(reduxActions.setActiveBankAccountMember(data.result))

    } catch (e) {
        console.log(e);
    }
}

function* fetchBankAccountMember(action: any) {
    try {
        const {data} = yield call(API.get, API_URLS.GET_BANK_ACCOUNT_LIST)
        yield put(reduxActions.setBankAccountMember(data.result))

    } catch (e) {
        console.log(e);
    }
}

function* fetchBankList(action: any) {
    try {
        const {data} = yield call(API.get, API_URLS.GET_BANK_LIST)
        yield put(reduxActions.setBankList(data.result))

    } catch (e) {
        console.log(e);
    }
}

function* createBankAccount(action: any) {
    try {

        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('acc_number', action.bankObject.acc_number)
        formData.append('acc_holder_name', action.bankObject.acc_holder_name)
        formData.append('bank_id', action.bankObject.bank_id)
        formData.append('branch', action.bankObject.branch)
        formData.append('city', action.bankObject.city)

        const {data} = yield call(API.post, API_URLS.CREATE_BANK_ACCOUNT, formData, {headers: headers})

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
        yield put(sagaActions.fetchBankAccountMember())

    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* deleteBankAccount(action: any) {
    try {

        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('bank_id', action.bankObject.bank_id)

        const {data} = yield call(API.post, API_URLS.DELETE_BANK_ACCOUNT, formData, {headers: headers})

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
        yield put(sagaActions.fetchBankAccountMember())
    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

function* updateBankAccount(action: any) {
    try {

        yield put(reduxCommonAction.makePageLoading(true))

        let headers = {"Content-Type": "multipart/form-data"}
        let formData = new FormData();
        formData.append('bank_id', action.bankObject.bank_id)
        formData.append('value', action.bankObject.status)

        const {data} = yield call(API.post, API_URLS.UPDATE_BANK_ACCOUNT, formData, {headers: headers})

        toast.success(data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
        yield put(sagaActions.fetchBankAccountMember())
    } catch (e) {
        toast.error(e.response.data.message, {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    } finally {
        yield put(reduxCommonAction.makePageLoading(false))
    }
}

const bankSaga = [
    takeLatest(SagaActionTypes.FETCH_ACTIVE_BANK_ACCOUNT_MEMBER, fetchActiveBankAccountMember),
    takeLatest(SagaActionTypes.FETCH_BANK_ACCOUNT_MEMBER, fetchBankAccountMember),
    takeLatest(SagaActionTypes.FETCH_BANK_LIST, fetchBankList),
    takeLatest(SagaActionTypes.CREATE_BANK_ACCOUNT, createBankAccount),
    takeLatest(SagaActionTypes.UPDATE_BANK_ACCOUNT, updateBankAccount),
    takeLatest(SagaActionTypes.DELETE_BANK_ACCOUNT, deleteBankAccount),
]

export default bankSaga
