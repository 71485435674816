import {ReduxActionTypes, SagaActionTypes} from './action-type'

//==============MIDDLE WARE======================
//==============SAGA======================

export const sagaActions = {
    fetchReportFundTransferList: (object): any => ({
        type: SagaActionTypes.FETCH_REPORT_FUND_TRANSFER_LIST,
        object
    }),

    fetchReportFundOutList: (object): any => ({
        type: SagaActionTypes.FETCH_REPORT_FUND_OUT_LIST,
        object
    }),

    fetchReportTotalList: (object): any => ({
        type: SagaActionTypes.FETCH_REPORT_TOTAL_LIST,
        object
    }),

}


//===============REDUX===================

export const reduxActions = {
    setReportFundTransferList: (reportFundTransferList): any => ({
        type: ReduxActionTypes.SET_REPORT_FUND_TRANSFER_LIST,
        reportFundTransferList
    }),

    setReportFundOutList: (reportFundOutList): any => ({
        type: ReduxActionTypes.SET_REPORT_FUND_OUT_LIST,
        reportFundOutList
    }),

    setReportTotalList: (reportTotalList): any => ({
        type: ReduxActionTypes.SET_REPORT_TOTAL_LIST,
        reportTotalList
    }),
}

